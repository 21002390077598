.item-box-content {
    margin-right: 12px;
}

.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;    
}

.profile {
   width:100%;
   height: 90%;
   object-fit: contain;
}

.avatar-large {
    vertical-align: middle;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    cursor: pointer;   
}

.card-content {
    border-radius: 1rem;
}

.pointer-none {
    pointer-events: none;
}

.cursor-none {
    cursor: not-allowed;
}

.img-border {
    border-radius: 1rem 0 0 1rem;
}

.letter-space {
    letter-spacing: 1px;
}


.btn-logout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 13px;
    justify-content: space-between;
}
.content-scroll{
    max-height: 75px;
    overflow-y: auto;
}

.logout {
    background-color: #dc3545;
    border-radius: 0.2rem;
    border-style: none;
    color: white;
    padding: 5px;
}

.btn-cancel {
    background-color: #6c757d;
    border-radius: 0.2rem;
    border-style: none;
    color: white;
    padding: 5px;
}

.designation-item .items {
    background: #e5e5e5;
    border-radius: 10px;
    min-height: 29px;
    padding: 3px 1rem
}

.designation-item .items p {
    line-height: 15px
}

.h-20px {
    height: 20px;
}
.h-30px {
    height: 30px;
}
.h-50px {
    height: 50px;
}

.w-100px {
    width: 100px;
}
.w-12r {
    width: 12rem;
}


.w-9r {
    width: 9rem;
}

.w-3r {
    width: 3rem;
}

.fs-13 {
font-size: 13px;
}

.item-boxes {
    background: #cfcfcf !important;
}

.content-area .item-row .item-boxes .item-img .edit-delete {
    background: #ffffff;
}

.content-area .item-row .item-boxes .item-img .edit-delete img {
    width: 30px;
}

.w-130px {
    width: 130px;
}

.w-250px {
    width: 250px;
}

.h-150px {
    height: 150px !important;
}

.w-150px {
    width: 150px;
}

.w-90px {
    width: 90px;
}

.w-200px {
    width: 200px;
}

img.searchCloseBtn {
    position: relative;
    background-color: #fff;
    width: 15px;
    bottom: 40px;
    margin: 5px;
}

.h-50px {
    height: 50px;
}

.min-h-78px {
    min-height: 78px;
}

.h-75px {
    height: 75px;
}

.cursor-pointer {
    cursor: pointer;
}

.round-img {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #a8a8a8;
    border-radius: 50%;
    cursor: pointer;
}

.avatar-img {
    width: 100%;
    height: 35px;
    object-fit: contain;
}

.font-20 {
    font-size: 20px;
}

.data-left {
    word-wrap: break-word;
}

.date-picker {
    height: 37px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
}
.z-index-2{
    z-index: 2;
}
.add-more-btn{
    min-width: 1rem;
    padding: 0rem 0.6rem;
    margin-right: 0.8rem;
}

.img-txt{
    font-size: 15px;
}